
const baseUrl =
  'https://api.telegram.org/bot6959665540:AAF-Hx9b3Gv3VuFep4sVjB880Cz6kzaDAyw/'

export const sendMessage = async (data) => {
  let message = 'Заявка Shoh-palace-grand\n '
  message += `Имя: ${data.name}, Номер телефона: ${data.phone}`
  const url = `${baseUrl}sendMessage`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      chat_id: '-4194881827',
      text: message,
    })
  })
  console.log(response, 'response')
}
